<template>
    <div class="image-page">
      <img :src="imageSrc" alt="对应的图片" />
      <!-- <router-link to="/">返回主页</router-link> -->
    </div>
  </template>
  
  <script>
  export default {
    props: ['id'],  // 获取路由参数
  
    computed: {
      imageId() {
        return this.$route.params.id;  // 获取路由中的 id
      },
      imageSrc() {
        const images = [
          '/images/image1.jpg',
          '/images/image2.jpg',
          '/images/image3.jpg',
          '/images/image4.jpg',
          '/images/image5.jpg',
          '/images/image6.jpg',
        ];
        return images[this.imageId - 1];  // 根据 id 返回对应的图片路径
      },
    },
  };
  </script>
  
  <style scoped>
  .image-page {
    width: 100%;
    height: 100vh;  /* 使图片容器占满整个视口 */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-page img {
    width: 100%;
    height: 100%;
    object-fit: contain;  /* 图片保持比例，适应容器 */
  }
  </style>
  