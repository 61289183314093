<template>
    <div class="home-page">
      <h1>光伏电站智能运维系统</h1>
      <div class="buttons">
        <button v-for="(name, id) in buttonNames" :key="id" @click="goToImage(id)">
          {{ name }}
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // 自定义按钮的名称
        buttonNames: {
          1: '分布式光伏发电出力精准预测系统',
          2: '绩效考核管理系统原型',
          3: '智慧电网',
          4: '光伏电站智能运维系统',
          5: '光伏电站部件结构详解',
          6: '光伏电气参数实时监测波形图',
        },
      };
    },
    methods: {
      goToImage(id) {
        this.$router.push(`/image/${id}`);  // 跳转到对应图片页面
      },
    },
  };
  </script>
  
  <style scoped>
  /* 页面整体样式 */
  .home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f0f4f8;
    font-family: 'Arial', sans-serif;
  }
  
  h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  /* 按钮容器样式 */
  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    padding: 10px;
  }
  
  /* 单个按钮样式 */
  button {
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: linear-gradient(135deg, #6a82fb, #fc5c7d);
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* 按钮悬浮效果 */
  button:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* 按钮点击效果 */
  button:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* 响应式设计，确保按钮在小屏幕上也能合适显示 */
  @media (max-width: 768px) {
    h1 {
      font-size: 1.5rem;
    }
  
    button {
      padding: 10px 20px;
      font-size: 14px;
    }
  }
  </style>
  