import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';  // 主界面
import ImagePage from '../views/ImagePage.vue'; // 图片页面

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,  // 主页面
  },
  {
    path: '/image/:id',  // 动态路由
    name: 'image',
    component: ImagePage,
    props: true,  // 将 route.params 作为组件的 props 传递
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
